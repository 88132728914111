import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Material-UI icon


import HideTextWithStar from "../../Common/HideTextWithStar";

import FamilyInfo from "../../Jobs/FamilyInfo";

const MyPostedJobCard = ({ jobDetails }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const selectedRole = localStorage.getItem("selectedRole");
    const handleCardClick = () => {

        if (selectedRole === 'employer') {
            navigate('/employer/my-job-post');
        } else {
            navigate(`/helper/job-detail/${jobDetails._id}`)
        }
    };

    const timeAgo = (date) => {
        const now = new Date();
        const publishedDate = new Date(date);
        const diffInMs = now - publishedDate; // difference in milliseconds
        const diffInMinutes = Math.floor(diffInMs / 1000 / 60); // difference in minutes

        if (diffInMinutes < 60) {
            return `${diffInMinutes} min. ago`;
        } else if (diffInMinutes < 1440) {
            const diffInHours = Math.floor(diffInMinutes / 60);
            return `${diffInHours} hr. ago`;
        } else {
            const diffInDays = Math.floor(diffInMinutes / 1440);
            return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
        }
    };

    const languagess = jobDetails?.requiredSkills?.language;
    const cares = jobDetails?.requiredSkills?.mainSkills?.care;
    const cookings = jobDetails?.requiredSkills?.mainSkills?.cooking;
    const householdChores = jobDetails?.requiredSkills?.mainSkills?.householdChore;
    const candidatePreference = jobDetails?.candidatePreference || [];
    const jobLocation = jobDetails?.basicInfo?.jobLocation || "";


    return (
        <>
            <div className="col-md-6 col-xl-12">
                <div
                    onClick={handleCardClick}
                    className="card border-hover-primary"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                    <div className="card-header border-0 pt-9">
                        <div className="card-title m-0">
                            <Typography variant="h6">DOMESTIC HELP</Typography>
                        </div>
                        <div className="card-toolbar">
                            <span className="status active">{jobDetails.jobStatus}</span>
                        </div>
                    </div>
                    <div className="card-body p-9" style={{ padding: '1rem 2.25rem !important' }}>
                        <div className="fs-3 fw-bold text-gray-900">
                            {jobDetails.jobDetails?.jobTitle}
                        </div>
                        <div className="fs-3">
                            <Typography
                                variant="body1"
                                style={{ marginTop: "-5px", display: "flex", alignItems: "center" }}
                            >
                                <LocationOnIcon style={{ marginRight: 4 }} aria-label="Job location" />
                                {jobLocation || "Location not specified"} |{" "}
                                <HideTextWithStar text={`Posted: ${timeAgo(jobDetails?.publishedAt)}`} />
                            </Typography>
                        </div>
                        {/* <p className="text-gray-500 fw-semibold fs-5 mt-1 mb-7">
                            {jobDetails.jobStatus}
                        </p> */}
                        <div className="fs-3">
                            <p>{jobDetails.jobDetails?.jobDescription}</p>
                        </div>
                        <div>
                            <Typography variant="body1">
                                <div><strong> <strong>{t("Required Skills :")}</strong></strong></div>
                                <ul className="skill-h" style={{ width: '100%' }}>

                                    {cares && cares.map((lang, index) => (
                                        <li key={index}><a href="javascript:void(0)">{lang}</a></li>
                                    ))}
                                    {cookings && cookings.map((lang, index) => (
                                        <li key={index}><a href="javascript:void(0)">{lang}</a></li>
                                    ))}
                                    {householdChores && householdChores.map((lang, index) => (
                                        <li key={index}><a href="javascript:void(0)">{lang}</a></li>
                                    ))}
                                </ul>
                                {/* <div><strong>Preference:</strong></div>
                                <ul className="skill-h">
                                    {candidatePreference?.candidateLocation && (
                                        <li>
                                            <a href="javascript:void(0)">Location: {candidatePreference.candidateLocation}</a>
                                        </li>
                                    )}

                                    {candidatePreference?.candidateAge && (
                                        <li>
                                            <a href="javascript:void(0)">Age: {candidatePreference.candidateAge}</a>
                                        </li>
                                    )}
                                    {candidatePreference?.candidateGender && (
                                        <li>
                                            <a href="javascript:void(0)">Gender: {candidatePreference.candidateGender}</a>
                                        </li>
                                    )}
                                    {candidatePreference?.candidateReligion && (
                                        <li>
                                            <a href="javascript:void(0)">Religion: {candidatePreference.candidateReligion}</a>
                                        </li>
                                    )}
                                    {candidatePreference?.candidateEducationLevel && (
                                        <li>
                                            <a href="javascript:void(0)">Education Level: {candidatePreference.candidateEducationLevel}</a>
                                        </li>
                                    )}
                                    {candidatePreference?.candidateExperience && (
                                        <li>
                                            <a href="javascript:void(0)">Experience: {candidatePreference.candidateExperience}</a>
                                        </li>
                                    )}
                                    {candidatePreference?.candidateNationality && (
                                        <li>
                                            <a href="javascript:void(0)">Nationality: {candidatePreference.candidateNationality}</a>
                                        </li>
                                    )}
                                </ul> */}
                            </Typography>
                        </div>
                        <div className="d-flex flex-wrap mb-5">
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                                <div className="fs-6 text-gray-800 fw-bold">
                                    <span>{jobDetails.applicants?.length}</span>
                                </div>
                                <div className="fw-semibold text-gray-500">
                                    New Applicatio
                                </div>
                            </div>
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                                <div className="fs-6 text-gray-800 fw-bold">
                                    <span>0</span>
                                </div>
                                <div className="fw-semibold text-gray-500">
                                    Conversation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
};

export default MyPostedJobCard;
