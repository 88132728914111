import React from 'react';
import HideTextWithStar from "../Common/HideTextWithStar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

const FamilyInfo = ({ jobDetails, jobPosted }) => {
    // Safely access and set default values for kidsCount and adultsCount
    const kidsCount = (jobDetails?.familyAgeGroups?.under3Years || 0) + (jobDetails?.familyAgeGroups?.between3And6 || 0);
    const adultsCount = jobDetails?.familyAgeGroups?.sevenYearsAndUp || 0;
    // const nationality = jobDetails?.aboutEmployer?.employerNationality || "";
    const nationality = " ";
    const petInfo = jobDetails?.aboutEmployer?.havePets === "YES" ? "pet " : "";
    const jobLocation = jobDetails?.basicInfo?.jobLocation || "";


    return (
        <>
            {adultsCount >= 1 ? `${adultsCount} ${adultsCount === 1 ? "adult" : "adults"}` : ''}
            {adultsCount >= 1 && kidsCount >= 1 ? ' + ' : ''}
            {kidsCount >= 1 ? `${kidsCount} ${kidsCount === 1 ? "kid" : "kids"}` : ''}
            {(adultsCount >= 1 || kidsCount >= 1) && petInfo ? ' + ' : ''}
            {petInfo ? `${petInfo}` : ''}
            {/* {(adultsCount >= 1 || kidsCount >= 1 || petInfo) && nationality ? ` | ${nationality}` : nationality || ''} */}
            {(adultsCount >= 1 || kidsCount >= 1 || petInfo) && nationality ? ` ${nationality}` : nationality || ''}

            {/* Job Location with FontAwesome Icon */}
            {(adultsCount >= 1 || kidsCount >= 1 || petInfo || nationality) && jobLocation ? (
                <> <FontAwesomeIcon icon={faMapMarkerAlt} /> {jobLocation}</>
            ) : jobLocation ? (
                <><FontAwesomeIcon icon={faMapMarkerAlt} /> {jobLocation}</>
            ) : ''}

            {/* Job Posted Section */}
            {jobPosted && (
                <div><HideTextWithStar text={`Posted: ${jobPosted}`} /></div>
            )}
        </>
    );
};

export default FamilyInfo;

